import React, { useEffect, useState } from 'react';
import { Detector } from 'react-detect-offline';
import { openDB } from 'idb';
import './App.css';
import spinner from './spinner.gif';
import fonts from './fonts.png';
import './css_sprites.png';

const poem = `
In the quiet of the offline night,
Disconnected from the world's light,
A poem whispers through the air,
A gentle reminder that we still care.
`;

let globalState = {
  readyForPayment: false,
  email: ''
};

function App() {
  const [storedPoem, setStoredPoem] = useState('');
  const [readyForPayment, setReadyForPayment] = useState(globalState.readyForPayment);
  const [email, setEmail] = useState(globalState.email);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const initDB = async () => {
      const db = await openDB('PoemDB', 1, {
        upgrade(db) {
          db.createObjectStore('poem', { keyPath: 'id' });
        },
      });

      await db.put('poem', { id: 'offlinePoem', text: poem });
    };

    initDB();
  }, []);

  useEffect(() => {
    const fetchPoem = async () => {
      const db = await openDB('PoemDB', 1);
      const poem = await db.get('poem', 'offlinePoem');
      setStoredPoem(poem?.text || '');
    };

    fetchPoem();
  }, []);



  

  useEffect(() => {
    const handleOnlineStatus = () => {
      console.log('Network status changed:', navigator.onLine);
      setIsOnline(navigator.onLine);
    };
    

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  const handlePrepareForPayment = () => {
    globalState.readyForPayment = true;
    globalState.email = email;
    setReadyForPayment(true);
    setModalIsOpen(true);
  };

  const handlePayment = async () => {
    if (!isOnline) {
      alert('You need to be online to complete the payment.');
      return;
    }

    try {
      const response = await fetch('https://surviveoffline.com/API-Survival/createcheckoutsession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const session = await response.json();
      if (session.error) {
        throw new Error(session.error);
      }

      // Redirect to Stripe Checkout page
      window.location.href = session.url;
    } catch (error) {
      console.error('Error during payment process:', error);
      alert('There was an error processing your payment. Please try again.');
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Detector
      render={({ online }) => {
        setIsOnline(online); // Update isOnline state based on the Detector's result
        return (
          <div className={`App ${modalIsOpen ? 'modal-open' : ''} ${online ? 'Apponline' : 'Appoffline'}`}>
            <div className={`ourcontainer ${online ? '' : 'outercontaineroffline'}`}>
              <div id="paymentModal" className={`modal ${modalIsOpen ? 'show' : ''}`}>
                <div className="modal-content">
                  <span className="close" onClick={closeModal}>&times;</span>
                  {online ? (
                    <>
                      <h2>Set Up Your SurviveOffline<br></br>30 Day Free Trial</h2>
                      <p>Email: {email}</p>
                      <button className="cta-button ctaenabled" onClick={handlePayment}>Continue Setup</button>
                    </>
                  ) : (
                    <>
                      <h2>Reconnect to Complete</h2>
                      <p>Connect to the internet to complete signup.</p>
                      <img src={spinner} alt="Loading..." className="loader" />
                      <button className="cta-button disabled" disabled>
                        Connect to Unlock Your 30 Days FREE!
                      </button>
                    </>
                  )}
                </div>
              </div>

              <header className="App-header">
                <div className="container">
                  {online ? (
                    <div className="online-view">
                      <h1 className="main-heading">Survive<u className='headertitle'>OFFLINE</u></h1>
                      <h2 className="sub-heading sub-headingonline">Toggle <span className="shake-airplane">Airplane Mode</span> to access!</h2>
                    </div>
                  ) : (
                    <div className="offline-view">
                      <h1 className="main-heading">
                        <span className="highlightoffline">YOU'RE OFFLINE! Either the grid is down, or you are preparing before it's too late...</span>
                      </h1>
                      <section className="hero">
                        <div className="hero-content">
                          <h2 className="hero-sub-heading">SurviveOffline<br></br>When The World Goes Dark!</h2>
                          <div className="cta-container top-cta">
                            <input
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter your email"
                              className="email-input"
                            />
                            <button className="cta-button cta-button2" onClick={handlePrepareForPayment}>
                              Start Your 30-Day Free Trial <div className="bg-chevron_right scaleicons2"></div>
                            </button>
                          </div>
                        </div>
                      </section>

                      <div className="offline-content">
                        <section className="feature-highlights">
                          <h3>SurviveOffline Includes:</h3>
                          <div className="feature-grid">
                            <div className="feature-item">
                              <div className="bg-wifi_slash scaleicons"></div>
                              <h4>24/7 Offline Access</h4>
                            </div>
                            <div className="feature-item">
                              <div className="bg-life_ring scaleicons"></div>
                              <h4>Life-Saving Info</h4>
                            </div>
                            <div className="feature-item">
                              <div className="bg-shield_halved_solid scaleicons"></div>
                              <h4>Emergency Preparedness</h4>
                            </div>
                            <div className="feature-item">
                              <div className="bg-compass scaleicons"></div>
                              <h4>Crucial Survival Skills</h4>
                            </div>
                            <div className="feature-item">
                              <div className="bg-head_side_brain scaleicons"></div>
                              <h4>Instant Knowledge Base</h4>
                            </div>
                            <div className="feature-item">
                              <div className="bg-users scaleicons"></div>
                              <h4>Join the Resistance</h4>
                            </div>
                          </div>
                        </section>

                        <section className="offline-section">
                          <h2 className="sub-heading">Imagine a world without internet—would you know what to do?</h2>
                          <p>
                            The grid is fragile. One solar flare, one cyber attack, one natural disaster, and it's gone.
                            When that happens, your smartphone can't connect. Online maps won't load. Search engines will be useless.
                          </p>
                          <p>But you? You'll have SurviveOffline.</p>
                          <p>This is your lifeline in the darkness - and it could be the difference between life and death. While others panic, you'll have instant access to crucial survival knowledge:</p>
                          <ul className="offline-list">
                            <li>How to find clean water</li>
                            <li>Which plants are safe to eat</li>
                            <li>How to build shelter from nothing</li>
                            <li>Basic medical procedures that could save a life</li>
                          </ul>
                          <p>
                            And that's just the beginning. SurviveOffline gives you access to an entire library of survival resources,
                            covering everything you could possibly need to know in any emergency situation. From advanced wilderness
                            survival techniques to urban disaster response, it's all here.
                          </p>
                          <p>
                            And it will all be available to you, no internet required. No cell service needed.
                            Just raw, vital information at your fingertips, when you need it most!
                          </p>
                          <p>The world is unpredictable. Governments fall. Natural disasters strike. Pandemics spread. The only question is: Will you be ready?</p>
                          <p>Don't be the one wishing you'd prepared. Be the one others turn to when shit hits the fan.</p>
                        </section>

                        <section className="offline-section bookshelf-section">
                          <h3>You'll have access to an entire Survival Library:</h3>
                          <div className="bookshelf">
                            <div className="book"><img src="https://surviveoffline.com/img/SurviveOffline2.jpg" alt="Survival Guide" /></div>
                            <div className="book"><img src="https://surviveoffline.com/img/SurviveOffline2.jpg" alt="Survival Guide" /></div>
                            <div className="book"><img src="https://surviveoffline.com/img/SurviveOffline2.jpg" alt="Survival Guide" /></div>
                            <div className="book"><img src="https://surviveoffline.com/img/SurviveOffline2.jpg" alt="Survival Guide" /></div>
                            <div className="book"><img src="https://surviveoffline.com/img/SurviveOffline2.jpg" alt="Survival Guide" /></div>
                            <div className="book"><img src="https://surviveoffline.com/img/SurviveOffline2.jpg" alt="Survival Guide" /></div>
                          </div>
                          <div className="bookshelf-overlay">
                            <p>+ Many More</p>
                          </div>
                        </section>

                        <section className="offline-section">
                          <h3>Join the Community:</h3>
                          <p>Knowledge shouldn't vanish when the Wi-Fi does. We're here to ensure it never will.</p>
                          <p>Survive Offline isn't just a tool—it's an act of rebellion. It's an uprising where knowledge flows freely, beyond the control of tech giants and fragile networks.</p>
                          <p>Our battle cry:</p>
                          <ul>
                            <i>Dethrone our digital overlords... Access knowledge, anytime, anywhere</i>
                          </ul>
                          <p>This isn't about fear—it's about freedom. Freedom to learn, grow, and thrive, even when the internet crumbles.</p>
                          <p>Join the swelling ranks that will stand strong when digital darkness falls. Be part of the SurviveOffline community and embrace the peace of mind that comes with true preparedness.</p>
                        </section>

                        <section className="offline-access-section">
                          <h3>When Everything Else Goes Dark, We're Still Here</h3>
                          <div className="comparison-grid">
                            <div className="comparison-item offline">
                              <div className="bg-circle_xmark scaleicons3"></div>
                              <span>Google</span>
                            </div>
                            <div className="comparison-item offline">
                              <div className="bg-circle_xmark scaleicons3"></div>
                              <span>Wikipedia</span>
                            </div>
                            <div className="comparison-item offline">
                              <div className="bg-circle_xmark scaleicons3"></div>
                              <span>YouTube</span>
                            </div>
                            <div className="comparison-item online">
                              <div className="bg-check_circle scaleicons3b"></div>
                              <span>SurviveOffline</span>
                            </div>
                          </div>
                          <p className="impactful-statement">When the world loses connection, you'll still have access to life-saving knowledge.</p>
                        </section>

                        <section className="cta">
                          <h2 className="sub-heading">For just $2 a week - less than a coffee - access life-saving knowledge when everything else is offline:</h2>
                          <div className="cta-container cta-container2">
                            <input
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter your email"
                              className="email-input"
                            />
                            <button className="cta-button" onClick={handlePrepareForPayment}>
                              Test Drive SurviveOffline<div className="bg-chevron_right scaleicons2"></div>
                            </button>
                          </div>
                          <p className="urgency-message">Sign up now, before the internet goes out for real. Don't be caught unprepared.</p>
                        </section>

                        <section className="guarantee">
                          <p>30-day money-back guarantee. But once you see what's inside, you won't want to leave.</p>
                        </section>
                      </div>

                      <footer>
                        <p className="poem">{storedPoem}</p>
                      </footer>
                    </div>
                  )}
                </div>
              </header>
            </div>
          </div>
        );
      }}
    />
  );
}

export default App;